import React from "react";
import logo from '../assets/coffe_coder_tr_bg.png';

export const HeroBanner = () => {


  return (
    <div className="hero-banner hero-banner--pink-yellow">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="React logo" />
      </div>
      <h1 className="hero-banner__headline">Hello, Welcome to Coffee Coder!</h1>
      <p className="hero-banner__description">
          A showcase of some of the awesome web apps that use <strong>artificial intelligence</strong> to create innovative features and solutions.
      </p>
    </div>
  );
};
