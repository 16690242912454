import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {PageLayout} from "../components/page-layout";
import ChatBox from "../components/ChatBox";

export const Chat = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <PageLayout>
      <ChatBox/>
    </PageLayout>
  );
};
