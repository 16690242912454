import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../coffe_coder_transparent.png";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
          <img src={logo} alt="Coffee Coder" width={55}/>
      </NavLink>
    </div>
  );
};
