import React from "react";
import { Auth0Feature } from "./auth0-feature";

import quiz_icon from '../assets/quiz_icon.svg'
import agri_icon from '../assets/agri_icon.svg'

export const Auth0Features = () => {
  const featuresList = [
    {
      title: "Agribot",
      description:
        "Agribot is a smart assistant that can answer questions related to paddy varieties. Agribot uses in-premise data and can help shop owner to answer customer queries",
      resourceUrl: "https://coffee-coder.gitesh.cloud/agribot",
      openInNewTab: false,
      icon: agri_icon,
    },
    {
      title: "QuizAI",
      description:
        "QuizAI is a quiz application that uses artificial intelligence to create multiple choice questions with options and explanations. QuizAI saves time by generating plausible but incorrect options for each question.",
      resourceUrl: "https://quiz.gitesh.cloud",
      openInNewTab: true,
      icon: quiz_icon,
    },
  ];

  return (
    <div className="auth0-features">
      <h2 className="auth0-features__title">Explore AI Apps</h2>
      <div className="auth0-features__grid">
        {featuresList.map((feature) => (
          <Auth0Feature
            key={feature.resourceUrl}
            title={feature.title}
            description={feature.description}
            resourceUrl={feature.resourceUrl}
            newTab={feature.openInNewTab}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
