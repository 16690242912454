import React, {useState} from 'react';
import axios from 'axios';
import '../styles/ChatBox.css'; // Import CSS file for styling
import {ReactComponent as CopyIcon} from './copy-icon.svg';
import {ReactComponent as BotIcon} from './robot-icon.svg';
import {ReactComponent as SendIcon} from './sent-icon.svg';
import {PageLoader} from "./page-loader";
import {useAuth0} from "@auth0/auth0-react";


const ChatBox = () => {
    const agribotApi = process.env.REACT_APP_AGRIBOT_API;
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();

            const response = await axios.post(agribotApi, {
                message: message,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(response);
            const newResponse = {
                message,
                response: response.data, // Assuming the response contains the message data
            };
            setResponses([newResponse, ...responses]);
            setMessage(''); // Clear input field after sending message
            setLoading(false);
            console.log(responses)
        } catch (error) {
            console.error('Error sending message:', error);
            // Handle error scenarios
        }
    };

    const reversedResponses = responses.slice().reverse();

    return (
        <div className="chat-container">

            <div className="chat-responses">

                {/* Display API responses */}
                {reversedResponses.map((response, index) => (
                    <div key={index} className="terminal">

                        <div className="terminal-header">
                            <span className="terminal-title">{response.message}</span>
                            <div className='copy-button-container' onClick={() => {
                                navigator.clipboard.writeText(response.response)
                            }}>
                                <CopyIcon className="copy-icon" fill="#f1f2f4"
                                          style={{width: "1.4rem", height: "1.4rem"}}/>
                                <span style={{paddingLeft: 8}}>Copy</span>
                            </div>
                        </div>
                        <div className="terminal-body">
                            <BotIcon fill="#f1f2f4" style={{width: "1.6rem", height: "1.6rem"}}/><br/>
                            {response.response}
                        </div>
                    </div>

                ))}

            </div>
            <div className="chat-input">
                {/* Input area for sending messages */}
                <div style={{flex: 1}}>
          <textarea
              className="chat-textarea"
              rows="4"
              value={message}
              onChange={handleMessageChange}
              disabled={loading}
              placeholder="Enter your message..."
          ></textarea>
                </div>

                <div className='send-button-container'>
                    <button className="send-button" onClick={handleSubmit} disabled={message.length <= 0 || loading}>

                        { loading ? (<PageLoader/>) : (<SendIcon className={message.length > 0 ? "close" : "close-disabled"}/>)}
                    </button>
                </div>


            </div>
        </div>
    );
};

export default ChatBox;
