import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../coffe_coder_transparent.png';

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
          <img src={logo} alt="Coffee Coder" width={65} />
      </NavLink>
    </div>
  );
};
