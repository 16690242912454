import React from "react";
import logo from './navigation/coffe_coder_transparent.png'

export const PageFooter = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-grid">

        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
            <img
              className="page-footer-brand__logo"
              src={logo}
              alt="Coffe Coder"

              width={30}

            />

          </div>
        </div>
      </div>
    </footer>
  );
};
